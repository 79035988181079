import axios from 'axios';
import { ctxValue } from './config';
import { tokenVerify } from './session';
import logger from './logger';

//Sign In

export async function getRelyingPartyInfo(subdomain, mobileNumber) {
  const mobileNbr = (mobileNumber) ? mobileNumber : 'none';
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + `/relyingParty/v1/${subdomain}/${mobileNbr}`
  ).then(result => { return result; }
  ).catch(err => {
    logger.error(`utils/services.js: getRelyingPartyInfo() - ${err}`);
    return {status:500, message:'System issue'};
  });
}

//Lookup Enroll Request information by enrollKey

export async function getEnrollRequestInfo(enrollKey) {
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + `/account/enroll/${enrollKey}`
  ).then(result => { return result; }
  ).catch(err => {
    if (err?.response?.status===404) {
      return {status:404, message:'Enroll request expired'};
    }
    logger.error(`utils/services.js: getEnrollRequestInfo() - ${err}`);
    return {status:500, message:'System error fetching enroll information'};
  });
}

//Lookup Auth Request information by authKey and customCode

export async function getAuthRequestInfo(authKey, customCode) {
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + `/account/auth/${authKey}/${customCode}`,
    { withCredentials: true } // enables cross-site cookie sharing
  ).then(result => { return result; }
  ).catch(err => {
    if (err?.response?.status===404) {
      return {status:404, message:'Auth request expired'};
    }
    logger.error(`utils/services.js: getAuthRequestInfo() - ${err}`);
    return {status:500, message:'System error fetching authentication information'};
  });
}

//Lookup Security Key Register Request information by skrKey

export async function getSecurityKeyRegisterInfo(skrKey) {
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + `/account/securityKeyRegister/${skrKey}`
  ).then(result => { return result; }
  ).catch(err => {
    if (err?.response?.status===404) {
      return {status:404, message:'Request expired'};
    }
    logger.error(`utils/services.js: getSecurityKeyRegisterInfo() - ${err}`);
    return {status:500, message:'System error fetching information'};
  });
}

//Update a Security Key Register request when a device FIDO key is already registered

export async function securityKeyRegisterExists(registerRequestId) {
  const token = tokenVerify();
  return axios.post(
    ctxValue('IDENTITY_SERVICE') + `/account/securityKeyRegisterExists/${registerRequestId}`,
    {}, // no body params
    { headers: { 'Authorization': `Bearer ${token}` }
    , withCredentials: true  // withCredentials causes cookies to be passed to the server
    }
  ).then(result => { return result; }
  ).catch(err => {
    if (err?.response?.status===404) {
      return {status:404, message:'Request not found'};
    }
    logger.error(`utils/services.js: securityKeyRegisterExists() - ${err}`);
    return {status:500, message:'System error fetching information'};
  });
}

//Lookup SMS magic link sign in verification by mnvKey

export async function mobileNumberVerifyInfo(mnvKey, customCode) {
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + `/mobileNumber/verifyInfo/${mnvKey}/${customCode}`
  ).then(result => { return result; }
  ).catch(err => {
    if (err?.response?.status===404) {
      return {status:404, message:'Request expired'};
    }
    logger.error(`utils/services.js: mobileNumberVerifyInfo() - ${err}`);
    return {status:500, message:'System error fetching information'};
  });
}

// Users response to the SMS magic link signin verification request

export async function mobileNumberVerifyUserResponse(subdomain, smsMagicLinkRequestId, status) {
  const token = tokenVerify();
  return axios.put(
    ctxValue('IDENTITY_SERVICE') + `/mobileNumber/verifyUserResponse/${subdomain}/${smsMagicLinkRequestId}/${status}`,
    {}, // no body params
    { headers: { 'Authorization': `Bearer ${token}` } }
  ).then(result => { return result; }
  ).catch(err => {
    if (err?.response?.status===404) {
      return {status:404, message:'Request expired'};
    }
    logger.error(`utils/services.js: mobileNumberVerifyUserResponse() - ${err}`);
    return {status:500, message:'System error fetching information'};
  });
}

//Record the user has Accepted the agreements

export async function acceptAgreements() {
  const token = tokenVerify();
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + '/account/acceptAgreements',
    { headers: { 'Authorization': `Bearer ${token}` } }
  ).then(result => { return result; }
  ).catch(err => {
    logger.error(`utils/services.js: acceptAgreements() - ${err}`);
    return {status:500, message:'System issue'};
  });
}

//Toggle RP identity verifications

export async function enableRelyingPartyVerifications(subdomain, enable) {
  const token = tokenVerify();
  return axios.get(
    ctxValue('IDENTITY_SERVICE') + `/login/relyingPartyEnable/v1/${subdomain}/${enable}`,
    { headers: { 'Authorization': `Bearer ${token}` } }
  ).then(result => { return result; }
  ).catch(err => {
    logger.error(`utils/services.js: enableRelyingPartyVerifications() - ${err}`);
    return {status:500, message:'System issue'};
  });
}

//Relying Party List

export async function getRelyingPartyList() {
  const token = tokenVerify();
  try {
    const response = await axios.get(
      ctxValue('IDENTITY_SERVICE') + `/account/relyingPartyList`,
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response;
  } catch (err) {
    logger.error(`utils/services.js: getRelyingPartyList() - ${err}`);
  }
  return {status:500, message:'System issue'};
}

//Verification Alert

export async function getVerificationRequest(subdomain, verifyCounter) {
  const token = tokenVerify();
  try {
    const response = await axios.get(
      ctxValue('IDENTITY_SERVICE') + `/verifyUser/verificationRequest/v1/${subdomain}/${verifyCounter}`,
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response;
  } catch (err) {
    if (err?.response?.status===401) {
      return {...err.response.data, status:401};
    }
    logger.error(`utils/services.js: getVerificationRequest() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

export async function verifyResponse(subdomain, verificationRequestId, status) {
  const token = tokenVerify();
  try {
    const response = await axios.post(
      ctxValue('IDENTITY_SERVICE') + `/verifyUser/confirm/v1/${subdomain}`,
      { verificationRequestId, status },
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response;
  } catch (err) {
    logger.error(`utils/services.js: verifyResponse() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

//Delete Account

export async function deleteIdgoAccount() {
  const token = tokenVerify();
  try {
    const response = await axios.delete(ctxValue('IDENTITY_SERVICE') + '/account/v1', {
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return response;
  } catch (err) {
    logger.error(`utils/services.js: deleteIdgoAccount() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

// Persist log messages

export async function logMessage(level, message, loginValue) {
  const token = tokenVerify();
  try {
    const response = await axios.post(
      ctxValue('IDENTITY_SERVICE') + '/account/logMessage',
      { level, message, userAgent: navigator?.userAgent, loginValue },
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response;
  } catch (err) {
    // Don't use the logger to log an error message or you may fall into an endless loop
    console.error(`utils/services.js: logMessage() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

// Created an IDgo auth cookie

export async function registerIDgoAuthCookie(subdomain) {
  const token = tokenVerify();
  try {
    const response = await axios.get(
      ctxValue('IDENTITY_SERVICE') + `/cookie/registerIDgoAuthCookie/${subdomain}`,
      { headers: { 'Authorization': `Bearer ${token}` }
      , withCredentials: true // enables cross-site cookie sharing
      }
    );
    return response;
  } catch (err) {
    logger.error(`utils/services.js: registerIDgoAuthCookie() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

// check to see if there is a cookie created from a 2nd factor authentication verification
// if verificationRequestId is provided, this request will be tied to a verification request record

export async function loginPreviousVerificationCookie(verificationRequestId) {
  const token = tokenVerify();
  const url = (verificationRequestId)
            ? ctxValue('IDENTITY_SERVICE') + `/cookie/previousVerificationCookie/${verificationRequestId}`
            : ctxValue('IDENTITY_SERVICE') + `/cookie/previousVerificationCookie`
            ;
  try {
    const response = await axios.get(
      url,
      { headers: { 'Authorization': `Bearer ${token}` }
      , withCredentials: true // enables cross-site cookie sharing
      }
    );
    return response;
  } catch (err) {
    // TODO: 401 check is temporary until identity-serivce corrests a incorrect "allowedRoutes" value for this route
    if (err?.response?.status!==404 && err?.response?.status!==401) {
      logger.error(`utils/services.js: loginPreviousVerificationCookie() - ${err}`);
    }
    return {status: err?.response?.status, message: err?.response?.data?.message};
  }
}

// Initiates the Verification / challenge code process

export async function loginVerificationCodeStart(loginValue, userAgreementShown) {
  const token = tokenVerify();
  try {
    const response = await axios.get(
      ctxValue('IDENTITY_SERVICE') + `/login/mobile/start/v2/${loginValue}/${userAgreementShown}`,
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response;
  } catch (err) {
    if (err?.response?.status===429) {
      logger.warn(`utils/services.js: loginVerificationCodeStart() - ${err}`, loginValue);
    } else {
      logger.error(`utils/services.js: loginVerificationCodeStart() - ${err}`, loginValue);
    }
    return {status: err?.response?.status, message: err?.response?.data?.message};
  }
}

// Verify (check) a verification code

export async function loginVerificationCodeCheck(loginValue, challengeCode, providerData) {
  const token = tokenVerify();
  let body = { challengeCode };
  if (providerData) body['providerData'] = providerData;
  try {
    const response = await axios.post(
      ctxValue('IDENTITY_SERVICE') + `/login/mobile/check/v2/${loginValue}`,
      body,
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return { data: response?.data, status: response?.status, message: response?.data?.message};
  } catch (err) {
    switch (err?.response?.status) {
      case 404: break;  // Twilio deletes the verification SID once it’s: expired, approved, max attempts reached - normal use case
      case 429: break;  // Too many requests - normal use case
      default:
        logger.error(`utils/services.js: loginVerificationCodeCheck() - ${err}`, loginValue);
    }
    return {status: err?.response?.status, message: err?.response?.data?.message};
  }
}

// Send a Toast "bubbles" message to all IDgo Agents listening to this IDgo Account
export async function clientActivity(eventName) {
  const token = tokenVerify();
  try {
    const response = await axios.get(
      ctxValue('IDENTITY_SERVICE') + `/account/clientActivity/v1/${eventName}`,
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return response;
  } catch (err) {
    logger.error(`utils/services.js: clientActivity() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

// Post a Toast "bubbles" message to all IDgo Agents listening to this IDgo Account
export async function clientActivityNoToken(eventName, authKey) {
  try {
    const response = await axios.get(
      ctxValue('IDENTITY_SERVICE') + `/account/clientActivity/v1/${eventName}/${authKey}`
    );
    return response;
  } catch (err) {
    logger.error(`utils/services.js: clientActivityNoToken() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

// Log Authentication event for CX analysis
export async function logSigninEvent(signinRequest, eventKey, eventDetails) {
  let body = {};
  if (eventDetails) body['eventDetails'] = eventDetails;
  try {
    await axios.post(
      ctxValue('IDENTITY_SERVICE') + `/verifyUser/logSigninEvent/${signinRequest?.signinType}/${signinRequest?.requestKey}/${eventKey}`,
      body
    );
    return {status:200};
  } catch (err) {
    logger.error(`utils/services.js: logSigninEvent() - ${err}`);
    return {status:500, message:'System issue'};
  }
}

// Register WebPush subscription

export async function registerWebPushSubscription(subscription, authKey) {
  const token = tokenVerify();
  try {
    await axios.post(
      ctxValue('IDENTITY_SERVICE') + `/account/webPushSubscription/register`,
      { authKey, subscription, userAgent: navigator?.userAgent },
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return {status:200};
  } catch (err) {
    if (err?.response?.status===404) {
      return {status:404, message:'CoziId not found'};
    }
    logger.error(`utils/services.js: registerWebPushSubscription() - ${err}`);
    return {status:500, message:'System error fetching information'};
  }
}

// UnRegister WebPush subscription

export async function unregisterWebPushSubscription(authKey) {
  const token = tokenVerify();
  try {
    await axios.post(
      ctxValue('IDENTITY_SERVICE') + `/account/webPushSubscription/unregister`,
      { authKey },
      { headers: { 'Authorization': `Bearer ${token}` } }
    );
    return {status:200};
  } catch (err) {
    if (err?.response?.status===404) {
      return {status:404, message:'Subscription not found'};
    }
    logger.error(`utils/services.js: unregisterWebPushSubscription() - ${err}`);
    return {status:500, message:'System error fetching information'};
  }
}
